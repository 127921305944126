@import 'color/colors';
@import 'mixins';

.PaperOSBranding {
  $mobile-header-font-size: 21pt;
  &__paperOS {
    color: $text;
    // color: $primary-dark;
    // color: $text__dark;
    font-weight: 600;
    font-size: 30pt;
  }
  &__dotgo {
    color: $white;
    // color: $link;
    font-weight: 500;
    font-size: 30pt;
  }

  &__heading {
    margin-top: 0;
    font-size: 60px;
    line-height: 71px;
    color: $dark;
    margin-bottom: 0;
    font-weight: 400;
    float: left;
    white-space: nowrap;
    @include screen-super-small {
      font-size: $mobile-header-font-size;
    }
  }
}
