@import 'color/colors';
@import 'mixins';

.Sitemap {
  &__Section {
    display: flex;
    background-color: $primary-dark;
    // background-color: $footer--bg;
    padding: 12px 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include layout-padding;
      // flex-direction: row;
      padding-top: 80px;
      padding-bottom: 80px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      @media (min-width: 1400px) {
        padding-top: 160px;
        padding-bottom: 160px;
      }
    }
  }
  &__pairedColumn {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    justify-content: center;
    max-width: 100%;
    padding: 0 15px 0 20px;

    @include screen-md {
      flex: 0 0 50%;
      max-width: 50%;

      &:first-child {
        padding-right: 45px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  &__joinUsWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__joinUsImage {
    height: 100px;
    margin-bottom: 12px;
  }

  &__joinUsSubheader {
    position: absolute;
    margin-top: 60px;
    font-size: 16px;
    margin-left: -78px;
    color: white;
  }
  &__joinUsHeader {
    color: $white;
    // color: $text__dark;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 0 15px;
    text-align: center;
    width: 100%;
    max-width: 450px;

    @include screen-md {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__joinUsText {
    color: white;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 auto 15px;
    text-align: center;
    width: 100%;

    @include screen-sm {
      max-width: 90%;
    }

    @include screen-md {
      font-size: 18px;
      line-height: 26px;
      max-width: 65%;
    }
  }

  &__joinUsButton {
    align-items: center;
    background-color: $new-primary;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 22px;
    font-weight: 400;
    height: 38px;
    margin: 20px 0 0;
    padding: 0 30px;
    transition: all 0.2s;
    // box-shadow: -2px 2px 5px 1px #777777cc;

    &:hover {
      background-color: $new-primary--light;
      box-shadow: 0px 4px 5px 1px #777777cc;
      transform: translateY(-1px);
    }
    @media (max-width: 768px) {
    }
  }

  &__introductionText {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 0 20px 28px;
      align-items: center;
    }
  }

  &__introductionText--large {
    font-size: 50px;
    line-height: 54px;
    color: white;
  }

  &__introductionText--normal {
    font-size: 18px;
    line-height: 21px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $white;
    .myBtn--link {
      font-size: inherit;
      color: $primary-light;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__Columns {
    display: flex;
    @media (max-width: 768px) {
      justify-content: space-around;
      margin-top: 40px;
    }
  }

  &__Column {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 8px;
    margin-bottom: 20px;
    h4 {
      color: $text__light;
      font-size: 20px;
      margin-top: 0;
    }
    a {
      color: $primary-light;
      // color: $text__dark-1;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      margin: 0 16px 0 0;
      width: fit-content;
      &:hover {
        color: $link;
      }
    }
    @media (max-width: 768px) {
      max-width: 120px;
    }
  }

  &__Footer {
    display: flex;
    justify-content: space-between;
    &Link {
      flex: 1;
      color: $mint;
      margin: 4px 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__socialMediaWrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    &:first-child {
      margin-right: 20px;
    }
  }

  &__socialMediaLink {
    color: $new-primary;
    font-size: 32px;
    margin-right: 15px;
    transition: color 0.2s;

    &:hover {
      color: $new-primary--light;
      transition: color 0.2s;
    }
  }

  // &__Section.isLight {
  //   background-color: $white;
  //   .Sitemap {
  //     &__joinUsHeader {
  //       color: $primary--light;
  //     }
  //     &__joinUsText {
  //       color: $primary--light;
  //     }
  //     &__Column {
  //       h4 {
  //         color: $primary--light;
  //       }
  //       a {
  //         color: $mint;
  //         text-decoration: underline;
  //       }
  //     }
  //   }
  // }
}
