@import 'color/colors';
@import 'mixins';

.LargeDropdown {
  &__wrapper {
    box-shadow: 0 11px 3px rgba(0,0,0,0.10);
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    position: absolute;
    background-color: white;
    z-index: 100;
    position: fixed;
    top: 76px;
    left: 0;
    @include screen-xs {
      float: left;
      clear: both;
    }
  }
  &__components {
    display: flex;
    justify-content: center;
    @include screen-xs {
      display: block;
    }
  }
}