@import 'mixins';
@import 'color/colors';

.GrayscaleRibbon {
  &__wrapper {
    padding: 30px 16px 20px;
    width: 100%;
    @include screen-lg {
      padding: 50px 0 40px;
    }
  }

  &__tileSectionHeader {
    color: $text__dark;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding: 0 0 8px;
    border-bottom: thin solid $border;
    @media (min-width: 1280px) {
      margin-bottom: 60px;
    }
  }

  &__tileWrapper {
    display: grid;
    grid-template-columns: repeat(2, 120px);
    justify-content: space-around;
    grid-auto-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 12px;

    @include screen-sm {
      grid-template-columns: repeat(3, 120px);
      justify-content: space-between;
      grid-row-gap: 20px;
    }

    @include screen-lg {
      grid-template-columns: repeat(4, 120px);
      grid-row-gap: 40px;
    }

    @include screen-xl {
      grid-template-columns: repeat(4, 120px);
    }
  }

  &__tile {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // height: 120px;
    width: 120px;
    .savvi-tooltip {
      top: -52px;
      bottom: unset;
    }
    img {
      max-height: 100%;
      width: 100%;
      object-fit: contain;
    }

    @media (max-width: 876px) {
      height: 100px;
      width: 120px;
    }

    @media (min-width: 1700px) {
      height: 140px;
      width: 160px;
    }
  }
}
