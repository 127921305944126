@import 'color/colors';

.savvi-tooltip {
  visibility: hidden;
  font-size: 16px;
  min-width: 120px;
  max-width: 300px;
  width: max-content;
  background-color: $tooltip;
  color: #fff;
  text-align: center;
  padding: 20px 30px;
  border-radius: 12px;
  position: absolute;
  z-index: 5;
  @media (max-width: 1080px) {
    max-width: 240px;
    font-size: 14px;
  }

  &--align-left {
    left: -16px;
  }
  &--align-center {
    left: 50%;
    transform: translateX(-50%);
  }
  &--align-right {
    right: -16px;
  }

  &--position-top {
    bottom: 28px;
  }
  &--position-right {
    top: -8px;
    left: 115%;
  }
  &--position-bottom {
    top: 80%;
  }
  &--position-left {
    top: -8px;
    right: 115%;
  }
  &--visible {
    visibility: visible;
  }
  &__wrapper {
    position: relative;
    cursor: help;
    margin-left: 2px;
    color: $text--light;
  }
  &__toggle:hover {
    color: $link;
  }
  :hover > & {
    visibility: visible;
    cursor: default;
  }
}
