@import 'color/colors';
@import 'mixins';

.HorizontalCard {
  cursor: pointer;
  &__wrapper {
    float: left;
    width: 28%;
    margin-right: 1em;
    border-radius: 10px;
    padding: 1em;
    /** This needs to be here otherwise the dropdown
      * will render poorly by nudging all the content
      * below it in a jittery way.
      */
    border: 3px solid white;
    /** Recover from hover */
    transition: all 0.2s ease-in-out;
    max-width: 420px;
    a {
      display: grid;
      place-items: center;
    }
    &:hover {
      /** Make the card bigger on hover */
      transform: scale(1.1);
      border: 3px solid $light-blue;
      position: relative;
    }

    &--stationary {
      &:hover {
        transform: none;
        border: 3px solid white;
        .HorizontalCard__link {
          text-decoration: underline;
        }
      }
    }

    &--invisible {
      cursor: none;
      opacity: 0;
      @media (max-width: 876px) {
        display: none;
      }
    }
    @include screen-xs {
      clear: both;
      min-width: 100%;
    }
    @include screen-range-md {
      min-width: 35%;
      padding: 0.5em;
      margin: 0em;
    }
    @media (min-width: 990px) {
      width: 32%;
    }
  }
  &__icon {
    height: 88px;
    color: $text__dark;
    justify-content: center;
    display: flex;
    margin-left: 2em;
    margin-right: 1em;
    @media (max-width: 1280px) {
      margin-left: 20px;
    }
    @media (max-width: 1080px) {
      margin-left: 0;
    }
    @media (max-width: 768px) {
      margin-left: 8px;
    }
  }
  &__content {
    padding-left: 8px;
  }
  &__link {
    color: $link;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
  }
  &__split {
    display: flex;
    justify-content: first baseline;
    @media (max-width: 768px) {
      width: 480px;
      max-width: 90vw;
    }
  }
  &__title {
    font-size: 1.2em;
    color: $link;
    font-weight: 330;
    margin: 0;
  }
  &__description {
    color: $text__dark;
    font-size: 0.85em;
    font-weight: 240;
  }
}
