@import 'color/colors';
@import 'mixins.scss';

.fullSpectrum {
  width: 100%;
  height: 100%;
}
.Topbar {
  box-shadow: 0 1px 3px #aaaaaa;
  padding: 20px 0;
  width: 100%;
  position: fixed;
  // background-color: $white;
  background-color: $bg--nav;
  left: 0;
  right: 0;
  &__active {
    filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
      brightness(95%) contrast(85%);
  }

  &__backdrop {
    position: fixed;
    left: 0px;
    top: 80px;
    z-index: 98;
  }

  &__dropdown--wrapper {
    background-color: $white;
    z-index: 99;
    width: 100%;
    // position: sticky;
    // top: 0;
    @include screen-super-small {
      max-width: 90%;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 40px;
    @include layout-padding;
  }

  &__companyLogo {
    // display: none;
    // filter: brightness(0%);
    height: 60px;

    @media (max-width: 768px) {
      height: 50px;
    }

    @include screen-md {
      display: block;
      // height: 35px;
      // filter: brightness(0%);

      &:hover {
        filter: brightness(90%);
      }
    }
  }

  &__companyMobileLogo {
    height: 35px;
    // filter: brightness(0%);

    @include screen-md {
      display: none;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: 4px;
    .myBtn {
      margin-right: 8px;
      min-width: 112px;
      @media (max-width: 768px) {
        margin-right: 4px;
        font-size: 12px;
        min-width: 80px;
      }
    }
    .myBtn--link {
      color: white;
      text-decoration: none;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-left: 16px;
    @media (max-width: 1080px) {
      .Topbar__pricingLink {
        margin: 0 16px;
      }
    }
    @include screen-super-small {
      // font-size: 16px;
      .Topbar__pricingLink {
        margin: 0 12px;
      }
    }
    @media (max-width: 568px) {
      margin-left: 8px;
      .Topbar__pricingLink {
        width: fit-content;
        font-size: 12px;
        margin: 0 8px;
        min-width: unset;
        svg {
          margin-left: 2px;
          font-size: 12px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  &__pricingLink {
    color: white;
    // color: $dark-75;
    font-weight: 400;
    transition: all 0.03s;
    margin: 0 30px 0 16px;
    min-width: 40px;
    &--active {
      color: $button;
    }
    &--optional {
      @media (max-width: 876px) {
        display: none;
      }
    }

    &:hover {
      color: $link--light;
      text-decoration: underline;
    }
    svg {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  &__loginBtn {
    align-items: center;
    background-color: transparent;
    border: 1px solid $primary--light;
    border-radius: 3px;
    color: $primary--light;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 34px;
    margin-left: 20px;
    padding: 0 15px;
    transition: all 0.2s;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      border-color: $primary--light-hover;
      color: $primary--light-hover;
      transition: all 0.2s;
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      transform: translateY(-1px);
    }
  }

  &__getStartedBtn {
    align-items: center;
    background-color: $primary--light;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 34px;
    margin-left: 15px;
    padding: 0 15px;
    transition: all 0.2s;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: $primary--light-hover;
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      transform: translateY(-1px);
    }
  }

  /** Used to stylize the "For Investors" dropdown since we need
   * to go against the grain to make the menu left-aligned
   */
  &__investors--component {
    //justify-content: left;
  }
}
