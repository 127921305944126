@import 'color/colors';
@import 'mixins';

.Footer {
  background-color: $bg--nav;
  // background-color: $footer--bg;
  border-top: 1px solid #fff;
  font-family: 'Roboto', sans-serif;
  padding: 35px 0;
  width: 100%;
  @media (min-width: 1400px) {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  &__footerWrapper {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;

    @include screen-sm {
      max-width: 540px;
    }

    @include screen-md {
      max-width: 720px;
    }

    @include screen-lg {
      max-width: 900px;
    }
  }

  &__disclaimer {
    color: $white;
    // color: $text__dark-1;
    font-size: 13px;
    line-height: 18px;
    margin: 0 0 12px;
    text-align: center;
  }

  &__copyright {
    color: $text__dark-1;
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 20px;
    text-align: center;
  }

  &__actionWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__linksWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
  }

  &__link {
    color: $text__dark-1;
    font-size: 12px;
    margin: 0 5px;
    text-decoration: underline;

    &:hover {
      color: $link;
    }
  }

  &__dotSeparator {
    color: $text__dark-1;
    margin: 0;
  }
}
