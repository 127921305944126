@import 'color/colors';
@import 'mixins';

.Funds {
  section {
    max-width: unset;
  }
  &__heroSection {
    overflow: visible;
    padding: 80px 15px 36px;
    height: 700px;
    @media (max-width: 1200px) {
      height: 640px;
    }
    // @media (max-width: 1200px) {
    //   padding-top: 80px;
    // }

    @media (max-width: 1080px) {
      height: 620px;
      .row {
        padding-right: 20px;
      }
    }

    @media (max-width: 976px) {
      height: 580px;
    }

    @media (max-width: 768px) {
      max-height: 940px;
      height: unset;
      .row {
        justify-content: center;
        flex-direction: column;
      }
    }
    @media (max-width: 676px) {
      max-height: 840px;
    }

    @media (min-width: 1640px) {
      max-height: 720px;
      .Funds__heroColumn {
        padding-top: 140px;
      }
      .Funds__heroImageWrapper {
        padding-top: 60px;
      }
      .Funds__heroImage {
        max-width: 680px;
      }
    }
  }

  &__heroHeading {
    margin-top: 0;
    font-size: 58px;
    color: $text__dark;
    // color: $dark;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 968px) {
      font-size: 50px;
    }
    @media (max-width: 876px) {
      font-size: 48px;
    }
    @media (max-width: 768px) {
      font-size: 52px;
    }

    @include screen-lg {
      font-size: 60px;
    }
  }

  &__heroSubtext {
    color: $text__dark;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0 0;
    padding: 0 15px;

    @media (max-width: 768px) {
      padding-left: 8px;
      font-size: 18px;
      margin-top: 20px;
    }

    @include screen-md {
      line-height: 20px;
      margin: 12px 0 0;
      padding: 0;
      max-width: 300px;
    }

    @include screen-lg {
      font-size: 18px;
      line-height: 23px;
      max-width: unset;
    }

    @include screen-xl {
      line-height: 26px;
    }
  }

  &__heroColumn {
    flex: 0 0 100%;
    padding: 0;
    max-width: 480px;
    text-align: center;
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    @media (max-width: 980px) {
      margin-top: 40px;
    }
    @media (max-width: 876px) {
      margin-top: 20px;
      min-width: 304px;
    }
    @media (max-width: 768px) {
      margin: 60px 40px 0;
      flex-direction: row;
      max-width: unset;
      text-align: left;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      margin: 48px 20px 0;
      .Funds__heroButton {
        margin-top: 40px;
        margin-left: 0;
      }
    }
    @include screen-md {
      padding-top: 60px;
      flex: 4.5;
      text-align: left;
    }
  }

  &__heroButton {
    // display: none;
    margin: 40px 0 0;
    align-self: flex-start;

    @media (max-width: 768px) {
      margin-left: 0px;
      margin-top: auto;
    }

    @include screen-md {
      display: inline-flex;
    }

    @include screen-lg {
      margin: 30px 0 0;
    }
  }

  &__heroImageWrapper {
    padding: 0 15px;
    flex: 0 0 100%;
    margin-top: 20px;
    max-width: 100%;
    position: relative;
    text-align: center;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    top: 26px;

    @media (min-width: 1400px) {
      top: 0;
    }

    @media (max-width: 1200px) {
      top: 20px;
    }

    @media (max-width: 768px) {
      align-items: center;
      top: 0;
      margin-top: 28px;
    }

    @include screen-md {
      padding-top: 0px;
      flex: 5.5;
      margin-top: 0;
    }
  }

  &__heroImage {
    max-height: 100%;
    width: 100%;
    max-width: 620px;
    @include screen-md {
      margin-right: -40px;
    }
    @media (min-width: 1400px) {
      max-width: 620px;
    }
    object-fit: contain;
  }

  &__heroImg {
    margin: 0 auto;
    max-width: 100%;
    width: 425px;
    position: relative;

    @include screen-md {
      width: 550px;
    }
  }

  &__floatingList {
    position: absolute;
    z-index: 0;
    left: 68px;
    bottom: 340px;
    @media (max-width: 1400px) {
      left: -28px;
    }
    @media (max-width: 1200px) {
      left: 28px;
      bottom: 240px;
    }
    @media (max-width: 980px) {
      bottom: 240px;
    }
    @media (max-width: 920px) {
      left: 0;
    }
    @media (max-width: 768px) {
      top: unset;
      bottom: 320px;
    }
  }
  &__floatingIcon {
    height: 26px;
    max-width: 26px;
  }

  &__section {
    padding-bottom: 30px;
  }

  &__sectionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1320px;
    &--even {
      flex-direction: row-reverse;
      .Funds__sectionItem--image {
        margin-left: 40px;
      }
    }
  }
  &__sectionItem {
    width: 90%;
    &--leftPadding {
      padding-left: 40px;
      // border-top: thin solid $border;
    }
    &--rightPadding {
      padding-right: 30px;
      // border-top: thin solid $border;
    }
    &--wide {
      width: 48%;
    }
  }
  &__sectionItemRow {
    display: flex;
    align-items: center;
    h2 {
      @include text__h2;
    }
    p {
      @include text__p;
    }
  }
  &__sectionItemImage {
    min-width: 500px;
    max-width: 520px;
    &--shadow {
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      margin: 12px;
    }
  }
  &__sectionItemIcon {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    color: $text__normal;
  }

  &__section--pricing {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 386px);
    justify-content: center;
    gap: 16px;
    margin-bottom: 40px;
    align-self: flex-start;
  }
  &__getStarted {
    grid-column: 1 / 4;
    p {
      @include text__p2;
      margin-bottom: -20px;
    }
  }
  &__pricingCard {
    height: 588px;
    border: 10px solid $light-blue-2;
    // background-color: $white;
    border-radius: 24px;
    padding: 40px 40px 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--even {
      background-color: $light-blue-2;
      .Founders__pricingCardPrice h2 {
        color: $button--light;
      }
    }
  }
  &__pricingCardList {
    flex: 1;
    h4 {
      @include text__bold;
      height: 48px;
    }
    ul {
      list-style: none;
      padding-left: 16px;
      .myBtn {
        margin-left: -18px;
        svg {
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }
    li {
      @include text__li;
    }
  }
  // &__pricingCardBottom {
  // }
  &__pricingCardPrePrice {
    @include text__p2;
  }
  &__pricingCardPrice {
    display: flex;
    align-items: flex-end;
    h2 {
      color: $light-blue-2;
      // color: #3ba8ed;
      font-size: 34px;
      font-weight: 500;
      margin: 0 12px 0 0;
    }
    p {
      @include text__p2;
    }
  }
  &__pricingCardPostPrice {
    margin-left: 12px;
    color: $text--dark-1;
    font-weight: 500;
    font-size: 16px;
  }
  &__pricingCardAction {
    margin: 12px 0;
    align-self: flex-start;
    &.myBtn--md {
      width: 100%;
    }
  }
  &__pricingCardFooter {
    height: 60px;
    h4 {
      font-size: 12px;
      font-weight: 400;
      color: $text--dark-1;
      line-height: 18px;
      margin: 0;
    }
  }

  @media (max-width: 1400px) {
    max-width: 1140px;
    width: 95%;
    &__topSectionSubheader {
      margin-right: 20px;
    }
  }
  @media (max-width: 1240px) {
    max-width: 1080px;
    width: 100vw;
    &__topSectionLeft {
      margin-left: 40px;
    }
    &__roundedList {
      top: -320px;
      left: -160px;
    }
    &__section--wide {
      margin: 0 40px;
    }
    &__section--pricing {
      grid-template-columns: repeat(3, 320px);
      align-self: auto;
    }
    &__pricingCard {
      padding: 30px;
      &--tall {
        height: 580px;
      }
    }
    &__pricingCardPrice {
      h2 {
        font-size: 28px;
      }
    }
  }
  @media (max-width: 1080px) {
    &__roundedList {
      top: -320px;
      left: -40px;
    }
    &__sectionRowNumeralWrapper {
      margin: 0 12px;
    }
    &__sectionItemImage {
      max-width: 42vw;
      margin-left: -20px;
      min-width: 480px;
    }
    &__section--pricing {
      grid-template-columns: repeat(3, 300px);
      gap: 4px;
    }
    &__getStarted {
      margin-left: 40px;
    }
    &__pricingCard {
      padding: 20px;
      &--tall {
        height: 580px;
      }
    }
  }
  @media (max-width: 960px) {
    &__section--pricing {
      grid-template-columns: repeat(3, 280px);
    }
    &__roundedList {
      left: -20px;
    }
  }
  @media (max-width: 876px) {
    &__topSectionImage {
      width: 520px;
    }
    &__roundedList {
      top: -320px;
      left: 0px;
      .RoundedList__wrapper {
        width: 340px;
      }
      li {
        font-size: 24px;
        img {
          width: 32px;
        }
      }
    }
    &__sectionItem--image {
      align-self: center;
    }
    &__section--pricing {
      grid-template-columns: repeat(2, 300px);
      gap: 20px;
    }
    &__pricingCard {
      height: 536px;
    }
    &__getStarted {
      grid-column: 1 / 3;
      margin-left: 0;
    }
    &__sectionRow {
      flex-direction: column;
      align-items: flex-start;
    }
    &__sectionRowNumeralWrapper {
      display: none;
    }
    &__sectionItemImage {
      max-width: 90vw;
    }
    &__sectionItem {
      width: 90%;
      &--leftPadding {
        padding-top: 20px;
        border-top: thin solid $border;
      }
      &--rightPadding {
        padding-top: 20px;
        padding-left: 40px;
        border-top: thin solid $border;
      }
    }
  }
  @media (max-width: 768px) {
    &__topSection {
      flex-direction: column;
    }
    &__topSectionSubWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__topSectionAction {
      margin-top: 0;
      margin-right: 40px;
    }
    &__topSectionImage {
      width: 90%;
    }
    &__roundedList {
      width: 100%;
      top: -280px;
      left: 40px;
      .RoundedList__wrapper {
        display: block;
        width: 320px;
        li {
          font-size: 20px;
          img {
            width: 28px;
          }
        }
      }
    }
  }
  @media (max-width: 646px) {
    &__section--pricing {
      grid-template-columns: repeat(1, minmax(320px, 400px));
    }
    &__pricingCard {
      height: fit-content;
    }
    &__getStarted {
      grid-column: 1 / 2;
      margin-left: 20px;
    }
  }
}
