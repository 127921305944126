@import 'color/colors';
@import 'mixins';

.Capital {
  section {
    max-width: unset;
  }
  &__heroSection {
    overflow: visible;
    padding: 80px 15px 36px;
    max-height: 600px;
    // background-color: $primary-light-bg;

    // @media (max-width: 1200px) {
    //   padding-top: 80px;
    // }

    @media (max-width: 1080px) {
      .row {
        padding-right: 20px;
      }
    }

    @media (max-width: 768px) {
      max-height: 812px;
      .row {
        justify-content: center;
        flex-direction: column;
      }
    }

    @media (min-width: 1640px) {
      max-height: 720px;
      .Capital__heroColumn {
        padding-top: 140px;
      }
      .Capital__heroImageWrapper {
        padding-top: 60px;
      }
      .Capital__heroImage {
        max-width: 680px
      }
    }
  }

  &__heroHeading {
    margin-top: 0;
    font-size: 58px;
    color: $text__dark;
    // color: $dark;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 968px) {
      font-size: 50px;
    }
    @media (max-width: 876px) {
      font-size: 48px;
    }
    @media (max-width: 768px) {
      font-size: 52px;
    }

    @include screen-lg {
      font-size: 60px;
    }
  }

  &__heroSubtext {
    color: $text__dark;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0 0;
    padding: 0 15px;

    @media (max-width: 768px) {
      padding-left: 8px;
      font-size: 18px;
      margin-top: 20px;
    }

    @include screen-md {
      line-height: 20px;
      margin: 12px 0 0;
      padding: 0;
      max-width: 300px;
    }

    @include screen-lg {
      font-size: 18px;
      line-height: 23px;
      max-width: unset;
    }

    @include screen-xl {
      line-height: 26px;
    }
  }

  &__heroColumn {
    flex: 0 0 100%;
    padding: 0;
    max-width: 480px;
    text-align: center;
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    @media (max-width: 980px) {
      margin-top: 40px;
    }
    @media (max-width: 876px) {
      margin-top: 20px;
      min-width: 304px;
    }
    @media (max-width: 768px) {
      margin-top: 60px;
      flex-direction: row;
      max-width: unset;
      text-align: left;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      .Capital__heroButton {
        margin-top: 40px;
        margin-left: 0;
      }
    }
    @include screen-md {
      padding-top: 60px;
      flex: 4.5;
      text-align: left;
    }
  }

  &__heroButton {
    // display: none;
    margin: 40px 0 0;
    align-self: flex-start;

    @media (max-width: 768px) {
      margin-left: 40px;
      margin-top: auto;
    }

    @include screen-md {
      display: inline-flex;
    }

    @include screen-lg {
      margin: 30px 0 0;
    }
  }

  &__heroImageWrapper {
    padding: 0 15px;
    flex: 0 0 100%;
    margin-top: 20px;
    max-width: 100%;
    position: relative;
    text-align: center;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    top: 26px;

    @media (min-width: 1400px) {
      top: 0;
    }

    @media (max-width: 1200px) {
      top: 20px;
    }

    @media (max-width: 768px) {
      align-items: center;
      top: 0;
      margin-top: 20px;
    }

    @include screen-md {
      padding-top: 0px;
      flex: 5.5;
      margin-top: 0;
    }
  }

  &__heroImage {
    max-height: 100%;
    width: 100%;
    max-width: 620px;
    @include screen-md {
      margin-right: -40px;
    }
    @media (min-width: 1400px) {
      max-width: 620px;
    }
    object-fit: contain;
  }

  &__heroImg {
    margin: 0 auto;
    max-width: 100%;
    width: 425px;
    position: relative;

    @include screen-md {
      width: 550px;
    }
  }

  &__floatingList {
    position: absolute;
    z-index: 0;
    left: 68px;
    bottom: 340px;
    @media (max-width: 1400px) {
      left: -28px;
    }
    @media (max-width: 1200px) {
      left: 28px;
      bottom: 240px;
    }
    @media (max-width: 980px) {
      bottom: 240px;
    }
    @media (max-width: 920px) {
      left: 0;
    }
    @media (max-width: 768px) {
      top: unset;
      bottom: 320px;
    }
  }
  &__floatingIcon {
    height: 26px;
    max-width: 26px;
  }
}

.home-top {
  margin-top: 100px;
  margin-left: 10px;

  button {
    width: 150px;
    height: 50px;
  }

  @media (max-width: 768px) {
    margin-top: 25px;
    margin-left: 0;

    button {
      width: 125px;
      height: 45px;
    }
  }
}

.home-info {
  height: 250px;
  color: $white;
  text-align: center;

  &__placeholder {
    height: 250px;
  }

  &__wrapper {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 250px;
    background-color: #282d31;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      max-width: 700px;
      margin: 0 0 10px;
      font-size: 2em;

      @media (max-width: 992px) {
        width: 600px;
      }

      @media (max-width: 768px) {
        width: 450px;
        font-size: 1.75em;
      }

      @media (max-width: 576px) {
        width: 80vw;
      }
    }

    h4 {
      width: 700px;
      margin: 0 15px;
      font-size: 1.25em;
      line-height: 1.5em;
      font-weight: 300;
      color: $white;

      @media (max-width: 992px) {
        width: 600px;
        font-size: 1em;
        line-height: 1.25em;
      }

      @media (max-width: 768px) {
        font-size: 1em;
        line-height: 1.25em;
        width: 450px;
      }

      @media (max-width: 576px) {
        width: 80vw;
      }
    }
  }

  &__action {
    width: 250px;

    .button {
      margin-top: 10px;
      width: 175px;
    }

    @media (max-width: 768px) {
      width: 150px;
    }
  }

  &__testimonials {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 992px) {
    margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.home-paired-sections {
  display: flex;
  width: 100%;

  h2 {
    margin-top: 0;
    color: $dark;
    font-size: 3.25em;
    font-weight: 400;

    @media (max-width: 1200px) {
      font-size: 3em;
    }

    @media (max-width: 768px) {
      font-size: 2.5em;
    }
  }

  h3 {
    font-size: 1.8em;
    color: $dark-85;
    font-weight: 300;
  }

  h4 {
    font-size: 1.5em;
    line-height: 1.5em;
    color: $dark-85;
    font-weight: 300;

    @media (max-width: 1200px) {
      font-size: 1.25em;
      line-height: 1.5em;
    }

    @media (max-width: 768px) {
      font-size: 1em;
      line-height: 1.25em;
    }
  }

  ul {
    margin-left: 0;
    padding-left: 20px;

    li {
      color: $dark-85;
      font-size: 1.5em;

      @media (max-width: 1200px) {
        font-size: 1.25em;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        font-size: 1em;
        line-height: 1.25em;
      }
    }
  }

  @media (max-width: 1200px) {
    margin-bottom: 100px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;

    &__flip {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.home-counsel {
  display: flex;
  flex-direction: column;
  box-shadow: -10px 15px 2px $light-110;
  border-radius: 6px;
  border: thin solid $light;
  padding: 10px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  p {
    margin: 0 0 2px 0;
  }

  h3 {
    margin-bottom: 15px;
    margin-top: 0;
    color: $dark-85;
    text-decoration: underline $primary;
    text-underline-position: under;
    font-weight: 400;
  }

  a:hover {
    text-decoration: underline;
  }

  .home-counsel__profile {
    display: flex;

    &-col {
      margin-right: 10px;
      text-align: center;
    }

    &-avatar {
      margin-top: 5px;

      img {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        margin-bottom: 5px;
      }
    }

    &-link {
      color: $blue;
    }
  }

  @media (max-width: 576px) {
    width: 90vw;
  }
}

.home-section__image {
  img {
    vertical-align: middle;
    width: 725px;

    @media (max-width: 1400px) {
      width: 600px;
    }

    @media (max-width: 1200px) {
      width: 540px;
    }

    @media (max-width: 992px) {
      width: 520px;
    }

    @media (max-width: 768px) {
      width: 500px;
    }

    @media (max-width: 576px) {
      width: 70vw;
    }
  }
}

.home-paired-section__top {
  flex: 1;
  margin: 0 0 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 992px) {
    margin: 10px auto;
    text-align: center;
  }
}

.home-paired-section {
  flex: 1;
  margin: 0 30px 20px;

  @media (max-width: 768px) {
    margin: 0 15px 20px;
    text-align: center;
  }
}

.home-paired-section__phone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;
  }
}

.home-section__screenshot {
  img {
    height: 625px;
    width: 600px;
    color: $light-90;
    box-shadow: 0 22px 24px;
    border-radius: 6px;
    border: thin solid $light-90;

    @media (max-width: 1200px) {
      height: 550px;
      width: 525px;
    }

    @media (max-width: 992px) {
      height: 550px;
      width: 525px;
    }

    @media (max-width: 768px) {
      height: 525px;
      width: 500px;
    }

    @media (max-width: 576px) {
      height: 73vw;
      width: 70vw;
    }
  }
}

.home-section {
  flex: 1;
  display: flex;
  min-width: 1075px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      max-width: 700px;
      margin: 0 0 10px;
      font-size: 2em;

      @media (max-width: 992px) {
        width: 600px;
      }

      @media (max-width: 768px) {
        width: 450px;
        font-size: 1.75em;
      }

      @media (max-width: 576px) {
        width: 80vw;
      }
    }

    h4 {
      margin: 0 15px;
      font-size: 1.5em;
      line-height: 1.5em;
      color: $dark-85;
      font-weight: 300;

      @media (max-width: 1200px) {
        font-size: 1.25em;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        font-size: 1em;
        line-height: 1.25em;
      }
    }

    @media (max-width: 768px) {
      width: 70vw;
    }
  }

  @media (max-width: 1200px) {
    margin-bottom: 100px;
    min-width: 500px;
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  @media (max-width: 576px) {
    width: 85vw;
    min-width: 300px;
  }
}

.home-section__testimonials {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.home__last {
  margin-bottom: 0;
  height: 301px;

  .home-info__wrapper {
    flex-direction: column;
    height: 300px;

    h4 {
      margin-bottom: 10px;
    }

    button {
      min-width: 175px;
      margin-top: 5px;
    }
  }
}
