@import 'color/colors';
@import 'mixins';

.TestimonialsList {
  &__wrapper {
    padding: 80px 16px 60px;
    width: 100%;
    @include screen-lg {
      padding: 70px 0 80px;
    }
  }
  &__header {
    color: $text__dark;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding: 0 0 8px;
    border-bottom: thin solid $border;
    margin-bottom: 40px;
    @media (min-width: 1280px) {
      margin-bottom: 60px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    column-gap: 16px;
    @include screen-md {
      grid-template-columns: 1fr 1fr;
    }
    @include screen-lg {
      row-gap: 24px;
      column-gap: 20px;
    }
    width: 100%;
    justify-content: space-between;
  }

  &__testimonial {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @include screen-md {
      max-width: 360px;
    }
    @include screen-lg {
      max-width: 460px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
    @media (min-width: 1080px) {
      max-width: 540px;
    }
    &Row {
      display: flex;
      align-items: center;
      height: 60px;
      h4 {
        font-size: 20px;
        color: $text__dark;
        font-weight: bold;
        margin: 0;
      }
      &--tall {
        padding-bottom: 12px;
      }
    }
    &Logo {
      background-color: transparent;
      position: relative;

      .savvi-tooltip {
        top: 0;
        bottom: unset;
      }
      img {
        max-height: 100%;
        width: 100%;
        object-fit: contain;
        position: relative;
      }
    }
    &Divider {
      height: 20px;
      width: 1px;
      margin: 0 20px 0 0;
      background-color: $border;
    }
    &Content {
      background-color: transparent;
      z-index: 10;
      p {
        color: $text__dark-1;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}
