// Small devices
@mixin screen-sm {
  @media (min-width: 576px) {
    @content;
  }
}

// Medium devices
@mixin screen-md {
  @media (min-width: 768px) {
    @content;
  }
}

// Large devices
@mixin screen-lg {
  @media (min-width: 992px) {
    @content;
  }
}

// Extra large devices
@mixin screen-xl {
  @media (min-width: 1200px) {
    @content;
  }
}

// Small devices
@mixin screen-xs {
  @media (max-width: 767px) {
    @content;
  }
}
// Tiny devices
@mixin screen-range-sm {
  @media (min-width: 0px) and (max-width: 767px) {
    @content;
  }
}

// Medium devices
@mixin screen-range-md {
  @media (min-width: 768px) and (max-width: 990px) {
    @content;
  }
}

// Large devices
@mixin screen-range-lg {
  @media (min-width: 991px) and (max-width: 1199px) {
    @content;
  }
}

@mixin layout-padding {
  margin: 0 auto;
  width: 100%;
  padding-left: 16px;
  padding-right: 0;
  padding-bottom: 0;

  @include screen-sm {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include screen-md {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include screen-lg {
    max-width: 960px;
    padding-left: 0;
    padding-right: 0;
  }

  @include screen-xl {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1280px;
  }
}

// Mobile devices
@mixin screen-super-small {
  @media (max-width: 767px) {
    @content;
  }
}
